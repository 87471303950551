import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import CSS from './InterviewComplete.module.scss';
import RootCSS from '../index.module.scss';
import liff from '@line/liff';

import Button from './partial/Button';
import Header from "./partial/Header";
import { List } from "@mui/material";

import useApiService from "../hooks/useApiService";

const InterviewComplete = () => {
  const history = useHistory();
  const location = useLocation();
  const propUserInfo = location.state.info;
  const propAnswer = location.state.answers;
  const { apiInterviewComplete } = useApiService();
  const [pdfUrl, updatePdfUrl] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hrefInterviewPdf = () => {
    propAnswer.other.value =  propAnswer.other.value === '' ? '無回答' : propAnswer.other.value; // 備考欄が入力されていない場合は無回答

    const param = {
      'user': propUserInfo,
      'answer': propAnswer
    }
    apiInterviewComplete(param)
      .then(response => {
        response.blob({type: 'application/octet-stream'})
          .then(blob => {
            // console.log(blob);
            // let reader = new FileReader();
            // reader.readAsDataURL(blob);
            // reader.onload = () => {
            //   const base64 = reader.result;
            //   console.log(base64);
            //   let windowParam = {
            //     url: base64,
            //     external: false,
            //   }
            //   liff.openWindow(windowParam);

            //   // let aTag = document.createElement('a');
            //   // aTag.href = base64;
            //   // aTag.target = '_blank';
            //   // aTag.click();
            //   // aTag.remove();
            // };

            history.push({
              pathname: '/interview/complete/pdf',
              state: {
                baseBlob: blob
              }
            });
          }, error => {
            console.log(error);
          });
      });
  }

  const testPdf = () => {
    propAnswer.other.value =  propAnswer.other.value === '' ? '無回答' : propAnswer.other.value; // 備考欄が入力されていない場合は無回答

    const param = {
      'user': propUserInfo,
      'answer': propAnswer
    }
    apiInterviewComplete(param)
      .then(response => {
        response.blob({type: 'application/octet-stream'})
          .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            let windowParam = {
              url: blobUrl,
              external: true,
            }
            liff.openWindow(windowParam);
            // let aTag = document.createElement('a');
            // aTag.download = `回答`;
            // aTag.href = blobUrl;
            // aTag.click();
            // aTag.remove();
          }, error => {
            console.log(error);
          });
      }, error =>{
        console.log(error);
      });
  }

  return (
    <>
      <Header back={false} />
      <div className={RootCSS.container}>
        <div className={CSS.confirmContainer}>
          {
            propAnswer.symptoms.map((symptom, index) => {
              return (
                <div className={CSS.symptomsItem} key={index}>
                  <h3>{symptom.title}</h3>
                  {
                    symptom.question.map((question, index) => {
                      return (
                        <dl key={index} className={Number(question.value) >= 3 && question.no !== '44' ? `${CSS.caution}` : ''}>
                          <dt>
                            <div className={CSS.questionNumber}>{question.no}.</div>
                            <div className={CSS.questionLabel}>{question.label}</div>
                          </dt>
                          {
                            question.no === '44' ?
                            <dd className={CSS.answer}>
                              {
                                (() => {
                                  switch(question.value) {
                                    case '4': // あり
                                      return 'あり';
                                    case '0': // なし
                                      return 'なし';
                                    default:
                                      return '無回答';
                                  }
                                })()
                              }
                            </dd>
                            :
                            <dd className={CSS.answer}>{question.value === '' ? '無回答' : question.value}</dd>
                          }
                        </dl>
                      )
                    })
                  }
                </div>
              )
            })
          }
          <h2>看護師と話をしたい事柄</h2>
          {
            propAnswer.consultation.map((consult, index) => {
              const emptyCheck = _.every(consult.question, (item) => {
                return item.value === '';
              })

              return (
                <div className={CSS.consultationItem} key={index}>
                  <h3>{consult.title}</h3>
                  {
                    emptyCheck ?
                    <div>無し</div>
                    :
                    <ul key={index}>
                    { 
                      consult.question.map((question, index) => {
                        return (
                          question.value === '' ?
                          ''
                          :
                          <li key={index}>{question.label}</li>     
                        )
                      })
                    }
                    </ul>
                  }
                </div>
              )
            })
          }
          <h2>その他</h2>
          <div className={CSS.otherItem}>
            {
              propAnswer.other.value === '' ?
              '無回答'
              :
              <p>{propAnswer.other.value}</p>
            }
          </div>
        </div>
        <div className={CSS.buttons}>
          <Button 
            color="red" 
            text="問診票を閲覧" 
            customeClass=""
            onClick={hrefInterviewPdf}
          />
          {/* <div style={{marginTop: '16px'}}>
            <Button 
              color="red" 
              text="ダウンロード検証" 
              customeClass=""
              onClick={testPdf}
            />
          </div> */}
        </div>
        {/* <a href="blob:http://localhost:3000/c5f66b67-3dfc-4d61-a9b4-f21d77cfc356" download="回答.pdf">TEST</a> */}
      </div>
    </>
  );
}

export default InterviewComplete;