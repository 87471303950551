import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import CSS from './Interview.module.scss';
import RootCSS from '../index.module.scss';
import dayjs from 'dayjs';
import * as _ from 'lodash';

import Button from './partial/Button';
import Header from "./partial/Header";

const Interview = (props) => {
  const history = useHistory();
  const todayDate = dayjs().format('YYYY-MM-DD');
  const INITIAL_USER_INFO = {
    'id': '',
    'name': '',
    'date': todayDate
  }
  const [userInfo, updateUserInfo] = useState(INITIAL_USER_INFO);
  const [disabledFlag, updateDisabledFlag] = useState(true);

  useEffect(() => {
    // 入力チェック
    const check = _.every(userInfo, (item) => {
      return item !== '';
    });
    updateDisabledFlag(!check);
  }, [userInfo])
  
  const updateInput = (key, event) => {
    const value = event.target.value;
    let clone = _.clone(userInfo);
    clone[key] = value;
    updateUserInfo(clone);
  }

  const hrefInterviewInput = (event) => {
    history.push({
      pathname: '/interview/input',
      state: {
        info: userInfo
      }
    });
  }

  return (
    <>
      <Header back={false}/>
      <div className={RootCSS.container}>
        <div className={CSS.interViewContainer}>
          <h1>LTFU問診票</h1>
          <p>
            ご自分の体調や看護師に聞きたい事について、記載をお願いします。<br />
            書かれた内容を基にお話を伺います。
          </p>

          <div className={CSS.inputInformation}>
            <div className={CSS.formItem}>
              <label>ID：</label>
              <div className={CSS.textInput}>
                <input type="text" defaultValue={userInfo.id} onChange={(event) => { updateInput('id', event)} } />
              </div>
            </div>
            <div className={CSS.formItem}>
              <label>お名前：</label>
              <div className={`${CSS.textInput} ${CSS.innerText}`}>
                <div className={CSS.inTextInput}>
                  <input type="text" defaultValue={userInfo.name} onChange={(event) => { updateInput('name', event) }} />
                  <span>様</span>
                </div>
              </div>
            </div>
            <div className={CSS.formItem}>
              <label>受診日：</label>
              <div className={CSS.textInput}>
                <input type="date" defaultValue={userInfo.date} onChange={(event) => { updateInput('date', event)} } />
              </div>
            </div>
          </div>
        </div>
        <div className={CSS.buttons}>
          <Button 
            color="red" 
            text="問診を開始" 
            customeClass=""
            onClick={hrefInterviewInput}
            disabled={disabledFlag}
          />
        </div>
      </div>
    </>
  );
}

export default Interview;