import React from "react";
import { Link } from 'react-router-dom';
import CSS from './Home.module.scss';
import RootCSS from '../index.module.scss';

import Header from "./partial/Header";

const Home = () => {
  return (
    <>
      <Header back={false} />
      <div className={RootCSS.container}>
        <div className={`${CSS.itemArea} ${CSS.kiroku}`}>
          <h3>記録を入力・確認する。</h3>
          <ul className={CSS.buttonList}>
            <li>
              <Link to="/memo">
                <div className={CSS.iconImage}>
                  <img src={`${process.env.PUBLIC_URL}/images/home/memo_icon.svg`} alt="記録を入力" />
                </div>
                <span>記録を入力</span>
              </Link>
            </li>
            <li>
              <Link to="/karte">
                <div className={CSS.iconImage}>
                  <img src={`${process.env.PUBLIC_URL}/images/home/karte_icon.svg`} alt="記録を確認" />
                </div>
                <span>記録を確認</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${CSS.itemArea} ${CSS.know}`}>
          <h3>FAQ・みんなの声を見る。</h3>
          <ul className={CSS.buttonList}>
            <li>
              <Link to="/faq">
                <div className={CSS.iconImage}>
                  <img src={`${process.env.PUBLIC_URL}/images/home/faq_icon.svg`} alt="FAQ" />
                </div>
                <span>FAQ</span>
              </Link>
            </li>
            <li>
              <Link to="/voice">
                <div className={CSS.iconImage}>
                  <img src={`${process.env.PUBLIC_URL}/images/home/voice_icon.svg`} alt="みんなの声" />
                </div>
                <span>みんなの声</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${CSS.itemArea} ${CSS.interview}`}>
          <h3>問診票を回答する。</h3>
          <ul className={CSS.buttonList}>
            <li>
              <Link to="/interview">
                <div className={CSS.iconImage}>
                  <img src={`${process.env.PUBLIC_URL}/images/home/interview_icon.svg`} alt="問診票" />
                </div>
                <span>問診票</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home;