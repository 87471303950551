import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import CSS from './KarteDetail.module.scss';
import RootCSS from '../index.module.scss';
import * as _ from 'lodash';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogButton from '@mui/material/Button';

import Header from './partial/Header';
import Button from './partial/Button';
import dayjs from "dayjs";

import useApiService from "../hooks/useApiService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const KarteDetail = (props) => {
  const history = useHistory();
  const location = useLocation();
  const INITIAL_MEMO_DETAIL_STATE = {
    "recordId": "",
    "created_at": "",
    "time": "",
    "kininarukoto": {
      "id": "",
      "label": ""
    },
    "shoujou": [],
    "detail": ""
  }; 
  
  const propRecordId = location.state.event.recordId;
  const propId = location.state.event.kininarukoto.id;
  const { apiMemoDetail, apiMemoDelete } = useApiService();
  const [memoDetail, updateMemoDetail] = useState(INITIAL_MEMO_DETAIL_STATE);
  const [isOpen, updateIsOpen] = useState(false);
  const [isDeleteCompleteOpen, updateIsDeleteCompleteOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const param = `/${propRecordId}`;
      const memoDetailResponse = await apiMemoDetail(param);
      if (memoDetailResponse) {
        updateMemoDetail(memoDetailResponse.detail);
      }
    };
    fetchData();
  }, []);

  const pop = () => {
    history.goBack();
  }

  const hrefKarteEdit = () => {
    history.push({
      pathname: `/karte/edit`,
      state: {
        memoDetailObj: memoDetail
      }
    });
  }

  const convertDate = (date) => {
    return dayjs(date).format('YYYY年MM月DD日');
  };

  const handleOpenDeleteDialog = () => {
    updateIsOpen(true);
  }

  const handleClose = () => {
    updateIsOpen(false);
  }

  const handleCloseBack = () => {
    updateIsOpen(false);
    const param = {
      'recordId': `${propRecordId}`
    }
    apiMemoDelete(param)
      .then(response => {
        updateIsDeleteCompleteOpen(true);

        setTimeout(() => {
          updateIsDeleteCompleteOpen(true);
          history.goBack();
        }, 2000);
      }, error => {
        console.log(error);
      })
  }

  return (
    <>
      <Header back={false} />
      <div className={RootCSS.container}>
        <div className={CSS.times}>{memoDetail.created_at}</div>
        <div className={CSS.detailContainer}>
          <dl className={CSS.date}>
            <dt>日時</dt>
            <dd>{memoDetail.time}</dd>
          </dl>
          <dl className={CSS.about}>
            {
              propId === 'reserve' ?
              ''
              :
              <dt>気になること</dt>
            }
            <dd>{memoDetail.kininarukoto?.label}</dd>
          </dl>
          {
            _.size(memoDetail.shoujou) > 0 ?
            <dl className={CSS.symptoms}>
              <dt>症状</dt>
              <dd>
                  {
                    _.get(memoDetail, 'shoujou', []).map((shoujou, index) =>{
                      return (
                        <span key={index}>{shoujou.label}</span>
                      )
                    })
                  }
                </dd>
            </dl>
            :
            ''
          }
          <div
            className={CSS.karteDetailTexts}
            dangerouslySetInnerHTML={
              { __html: memoDetail.detail }
            }
          ></div>
        </div>
      </div>
      <div className={CSS.footer}>
        <button type="button" onClick={handleOpenDeleteDialog}>
          <img src={`${process.env.PUBLIC_URL}/images/memo_delete.svg`} alt="削除" />
          <span>削除</span>
        </button>
        <button type="button" onClick={hrefKarteEdit}>
          <img src={`${process.env.PUBLIC_URL}/images/memo_edit.svg`} alt="編集" />
          <span>編集</span>
        </button>
      </div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={CSS.deleteDialog}>
          <DialogContentText>
            記録を削除します。<br />よろしいですか。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogButton onClick={handleClose}>
            いいえ
          </DialogButton>
          <DialogButton onClick={handleCloseBack} autoFocus>
            はい
          </DialogButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteCompleteOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={CSS.deleteDialog}>
          <DialogContentText>
            削除しました。
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default KarteDetail;