import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import CSS from './KarteEdit.module.scss';
import RootCSS from '../index.module.scss';
import * as _ from 'lodash';
import dayjs from 'dayjs';

import Header from './partial/Header';
import Button from './partial/Button';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';

import useApiService from "../hooks/useApiService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const KarteEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const { apiMemoInput, apiImageUpload, apiMemoSave } = useApiService();
  const textAreaRef = React.createRef();

  const propMemoDetail = location.state.memoDetailObj;
  const propRecordId = propMemoDetail.recordId;

  const initialKininarukotoState = propMemoDetail.kininarukoto;
  const initialShoujouState = [];
  const initialDateTime = dayjs(propMemoDetail.time).format('YYYY-MM-DDThh:mm:ss');
  const initialTextareaInput = propMemoDetail.detail;

  const [date, updateDate] = useState(initialDateTime);
  const [typeData, updateTypeData] = useState([]);
  const [kininarukotoObjects, updateKininarukotoObjects] = useState(initialKininarukotoState);
  const [shoujouObjects, updateShoujouObjects] = useState(initialShoujouState);
  const [textareaInput, updateTextAreaInput] = useState(initialTextareaInput);

  const [isOpen, updateIsOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const param = {
        'edit': true,
        'recordId': `${propRecordId}`
      };
      const memoResponse = await apiMemoInput(param);
      if (memoResponse) {
        updateTypeData(memoResponse.kiroku);
        const targetObj = _.filter(memoResponse.kiroku, (item) => {
          return item.kininarukoto.id === propMemoDetail.kininarukoto.id;
        });
  
        if (_.isEmpty(targetObj)) {
          updateShoujouObjects(initialShoujouState);
        } else {
          updateShoujouObjects(targetObj[0].shoujou);
        }
  
        const targetElement = document.getElementById('inputArea');
        targetElement.innerHTML = initialTextareaInput;
      }
    };
    fetchData();
  }, [])

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const targetObj = _.filter(typeData, (item) => {
      return item.kininarukoto.id === value;
    });

    if (_.isEmpty(targetObj)) {
      updateKininarukotoObjects(initialKininarukotoState);
      updateShoujouObjects(initialShoujouState);
    } else {
      updateKininarukotoObjects(targetObj[0].kininarukoto);
      updateShoujouObjects(targetObj[0].shoujou);
    }
  }

  const changeTextArea = (event) => {
    updateTextAreaInput(event.target.innerHTML);
  }

  const imageUpload = async (event) => {
    let param = new FormData();
    const file = event.target.files[0];
    param.append('file', file);
    
    try {
      const imageUploadResponse = await apiImageUpload(param);
      const url = imageUploadResponse.image;
      const imgElement = `<img src="${url}" />`;
      const targetElement = document.getElementById('inputArea');
      targetElement.insertAdjacentHTML('beforeend', imgElement);
      updateTextAreaInput(targetElement.innerHTML);
      const inputElement = document.getElementById('uploadFile');
      inputElement.value = '';
      targetElement.focus();
    } catch(error) {
      console.log(error);
    }
  }

  const handleClose = () => {
    updateIsOpen(false);
    history.goBack();
  }

  const handleCheckbox = (event) => {
    const value = event.target.value;
    const replaceShoujouObjects = _.map(shoujouObjects, (item) => {
      if (item.id === value) {
        item.checked = !item.checked;
        return item;
      } else {
        return item;
      }
    });
    updateShoujouObjects(replaceShoujouObjects);
  }

  const saveKarteEdit = () => {
    const param = {
      'update': true,
      'recordId': `${propRecordId}`,
      'time': date,
      'kininarukoto': kininarukotoObjects,
      'shoujou': shoujouObjects,
      'detail': textareaInput
    }

    apiMemoSave(param)
      .then(response => {
        updateIsOpen(true);

        setTimeout(() => {
          handleClose();
        }, 2000);
      }, error => {
        console.log(error);
      });
  }

  return (
    <>
      <Header back={false} />
      <div className={RootCSS.container}>
        <h1 className={CSS.title}>日時</h1>
        <div className={CSS.datetime}>
          <input type="datetime-local" defaultValue={date} onChange={(event) => {updateDate(event.target.value)}} />
        </div>
        {
          kininarukotoObjects.id === 'reserve' ?
          ''
          :
          <h1 className={CSS.title}>気になること</h1>
        }
        <div className={CSS.about}>
          <FormControl fullWidth>
            <Select
              labelId="about-select"
              id="about-select-id"
              value={kininarukotoObjects.id}
              onChange={handleSelectChange}
              input={<OutlinedInput />}
              className={CSS.customMuiSelect}
            >
              {
                typeData.map((type, index) => {
                  const id = type.kininarukoto.id;
                  return (
                    <MenuItem key={index} value={id}>{type.kininarukoto.label}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </div>
        <FormGroup>
          {
            shoujouObjects.map((shoujou, index) => {
              return (
                <FormControlLabel 
                  key={index} 
                  className={CSS.checkBoxItem} 
                  control={
                    <Checkbox 
                      name={kininarukotoObjects.id} 
                      value={shoujou.id}
                      onChange={(event) => { handleCheckbox(event) }}
                      checked={shoujou.checked} 
                    />
                  } 
                  label={shoujou.label} 
                />
              )
            })
          }
          {
            kininarukotoObjects.id === '' ?
            ''
            :
            <div className={CSS.freeInput}>
              <div 
                className={CSS.textarea} 
                ref={textAreaRef} 
                id="inputArea" 
                value={textareaInput} 
                onInput={changeTextArea} 
                contentEditable="true" 
                placeholder="自由記載"
              ></div>
            </div>
          }
          {
            kininarukotoObjects.id === 'skin' || kininarukotoObjects.id === 'reserve' ?
            <div className={CSS.imageUpload}>
              <label htmlFor="uploadFile" className={CSS.uploadButton}>
                <img src={`${process.env.PUBLIC_URL}/images/camera.svg`} alt="カメラ" />
                <input type="file" name="uploadFile" id="uploadFile" accept="image/*" defaultValue="" onChange={imageUpload}/>
              </label>
            </div>
            :
            ''
          }
        </FormGroup>
        <div className={CSS.buttons}>
          <Button 
            color="red" 
            text="保存" 
            customeClass=""
            onClick={saveKarteEdit}
          />
        </div>
      </div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={CSS.saveDialog}
      >
        <DialogContent className={CSS.saveDialogContainer}>
          保存しました。
        </DialogContent>
      </Dialog>
    </>
  );
}

export default KarteEdit;