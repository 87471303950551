import React, { useEffect, useState } from "react";
import './App.module.scss';
import { Route, useLocation } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
// import liff from '@line/liff';
import CSS from './App.module.scss';

import Home from './components/Home';
import Faq from './components/Faq';
import Memo from './components/Memo';
import MemoInput from './components/MemoInput';
import Karte from './components/Karte';
import KarteDetail from './components/KarteDetail';
import KarteEdit from './components/KarteEdit';
import Voice from './components/Voice';
import Interview from './components/Interview';
import InterviewInput from './components/InterviewInput';
import InterviewComplete from './components/InterviewComplete';
import InterviewCompletePdf from './components/InterviewCompletePdf';

import useApiService from "./hooks/useApiService";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogButton from '@mui/material/Button';

import { useRecoilState } from 'recoil';
import { dialogState } from './stores/atom';
import { spinnerState } from './stores/atom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const App = () => {
  const location = useLocation();
  const [state, updateState] = useRecoilState(dialogState);
  const [spinner, updateSpinnerState] = useRecoilState(spinnerState);

  const handleClose = () => {
    updateState({'flag': false, 'message': ''});
  }

  return (
    <>
      <SlideRoutes location={location} pathList={[]} destroy={true}>
        <Route exact path="/" component={Home} />
        <Route path="/faq" component={Faq} />
        <Route exact path="/memo" component={Memo} />
        <Route path="/memo/input" component={MemoInput} />
        <Route exact path="/karte" component={Karte} />
        <Route path="/karte/detail" component={KarteDetail} />
        <Route path="/karte/edit" component={KarteEdit} />
        <Route path="/voice" component={Voice} />
        <Route exact path="/interview" component={Interview} />
        <Route path="/interview/input" component={InterviewInput} />
        <Route exact path="/interview/complete" component={InterviewComplete} />
        <Route path="/interview/complete/pdf" component={InterviewCompletePdf} />
      </SlideRoutes>
      <Dialog
        open={state.flag}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={CSS.errorDialog}
      >
        <DialogContent className={CSS.errorDialogContainer}>
          <DialogContentText className={CSS.errorDialogContainerText}>
            {state.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogButton onClick={handleClose} className={CSS.errorDialogActionButton}>
            OK
          </DialogButton>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        open={spinner.flag}
        classes={CSS.backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default App;
