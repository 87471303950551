import React, { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';

import CSS from './Faq.module.scss';
import RootCSS from '../index.module.scss';
import * as _ from 'lodash';

import Header from "./partial/Header";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useApiService from "../hooks/useApiService";

const Faq = (props) => {
  const history = useHistory();
  const [faqs, updateFaqs] = useState([]);
  const [allFaqs, updateAllFaqs] = useState([]);
  const { apiFaq } = useApiService();
  const [searchFlag, updateSearchFlag] = useState(false);
  
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const param = '';
      const faqResponse = await apiFaq(param);
      updateFaqs(faqResponse.faq);
      updateAllFaqs(faqResponse.faq);
    };
    fetchData();
  }, [])

  const toggleSearch = () => {
    const flag = !searchFlag;
    if (flag) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
    updateSearchFlag(flag);
  }

  const inputSearchWord = (event) => {
    const value = event.target.value;
    if (value === '') {
      updateFaqs(allFaqs);
    } else {
      const targetObj = _.filter(allFaqs, (item) => {
        return _.includes(item.question, value);
      });
      updateFaqs(targetObj);
    }

    window.scrollTo({top: 0});
  }
  
  return (
    <>
      <Header back={false} router={history} />
      <div className={RootCSS.container}>
        <div className={CSS.faqWrapper}>
          <div className={CSS.faqItemWrapper}>
            {
              faqs.map((faq, index) => {
                return (
                  <Accordion key={index} className={CSS.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      className={CSS.faqAccordionTitle}
                    >
                      <div
                        dangerouslySetInnerHTML={
                          { __html: faq.question }
                        }
                      ></div>
                    </AccordionSummary>
                    <AccordionDetails className={CSS.faqAccordionDetail}>
                      <div
                        dangerouslySetInnerHTML={
                          { __html: faq.answer }
                        }
                      ></div>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </div>
          <div className={`${CSS.floatingSearch} ${searchFlag ? CSS.open : ''}`}>
            <div className={CSS.searchIcon} onClick={toggleSearch}>
              <img src={`${process.env.PUBLIC_URL}/images/search_icon.svg`} className={CSS.search} alt="検索" />
              <img src={`${process.env.PUBLIC_URL}/images/close_icon.svg`} className={CSS.close} alt="閉じる" />
            </div>
            <div className={CSS.searchInput}>
              <input type="text" placeholder={`検索ワードを入力して下さい。`} onBlur={inputSearchWord} ref={inputRef} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;