import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import CSS from './InterviewCompletePdf.module.scss';
import RootCSS from '../index.module.scss';
import * as _ from 'lodash';

import { useRecoilState } from 'recoil';
import { spinnerState } from '../stores/atom';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const InterviewCompletePdf = () => {
  const location = useLocation();
  const screenWidth = window.screen.width;
  const [fileUrl, updateFileUrl] = useState('');
  const [pageNums, updatePageNums] = useState([]);
  const [downloadLink, updateDownloadLink] = useState('');
  const [spinner, updateSpinnerState] = useRecoilState(spinnerState);
  
  const propBlob = location.state.baseBlob;
  useEffect(() => {
    updateSpinnerState({'flag': true});
    const url = window.URL.createObjectURL(propBlob);
    updateFileUrl(url);
    updateDownloadLink(url);
  }, [])

  const load = (pdf) => {
    const num = Number(pdf.numPages) + 1;
    const range = _.range(1, num, 1);
    updatePageNums(range);
  }
  // let reader = new FileReader();
  // reader.readAsDataURL(propBlob);
  // reader.onload = () => {
  //   const base64 = reader.result;
  //   console.log(base64);
  //   updateBase64(base64);
  // }

  const pageRender = () => {
    updateSpinnerState({'flag': false});
  }
  
  return (
    <>
      {
        fileUrl === '' ?
        ''
        :
        <Document 
          file={fileUrl} 
          onLoadSuccess={load} 
          className={CSS.viewer}
          loading={''}
          noData={''}
          canvasBackground={'#000000'}
        >
          {
            pageNums.map((num, index) => {
              return (
                <Page 
                  pageNumber={num} 
                  width={screenWidth} 
                  key={index} 
                  className={CSS.viewerPage} 
                  renderTextLayer={false}
                  loading={''} 
                  onRenderSuccess={pageRender}
                />
              )
            })
          }
        </Document>
      }
      {/* {
        downloadLink ?
        <a href={downloadLink} className={CSS.downloadButton} download>
          <img src={`${process.env.PUBLIC_URL}/images/pdf_download.svg`} alt="PDFダウンロード" />
        </a>
        :
        ''
      } */}
    </>
  );
}

export default InterviewCompletePdf;