module.exports = {
  apiUrl: `${process.env.PUBLIC_URL}`,
  apiInfos: {
    Faq: {
      method: 'GET',
      path: '/sample_data/api_faq.json'
    },
    Voice: {
      method: 'GET',
      path: '/sample_data/api_voice.json'
    },
    MemoInput: {
      method: 'GET',
      path: '/sample_data/api_memo_input.json'
    },
    MemoList: {
      method: 'GET',
      path: '/sample_data/api_memo_list.json'
    },
    MemoDetail: {
      method: 'GET',
      path: '/sample_data/api_memo_detail.json'
    },
    MemoDelete: {
      method: 'GET',
      path: '/sample_data/api_memo_delete.json'
    },
    MemoSave: {
      method: 'GET',
      path: '/sample_data/api_memo_save.json'
    },
    ImageUpload: {
      method: 'GET',
      path: '/sample_data/api_image_upload.json'
    },
    InterviewComplete: {
      method: 'GET',
      path: '/pdf/sample.pdf'
    }
  }
};
