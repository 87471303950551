import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import CSS from './InterviewInput.module.scss';
import RootCSS from '../index.module.scss';

import MASTER from '../INTERVIEW_MASTER.json';

import Button from './partial/Button';
import Header from "./partial/Header";

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogButton from '@mui/material/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InterviewInput = (props) => {
  const history = useHistory();
  const location = useLocation();
  const propUserInfo = location.state.info;

  const INITIAL_ANSWER = {
    'symptoms': MASTER.symptoms,
    'consultation': MASTER.consultation,
    'other': MASTER.other
  }

  const [answer, updataAnswer] = useState(INITIAL_ANSWER);
  const [isOpen, updateIsOpen] = useState(false);
  const [emptyNumbers, updateEmptyNumbers] = useState([]);

  const hrefInterviewComplete = () => {
    // 未選択の項目をチェック
    const noAnswerNumbers = _.map(_.flatten(_.map(answer.symptoms, (item) => {
      return _.filter(item.question, (item2) => {
        return item2.value === '';
      });
    })), 'no');

    if (_.size(noAnswerNumbers) > 0) {
      updateEmptyNumbers(noAnswerNumbers);
      updateIsOpen(true);
    } else {
      history.push({
        pathname: '/interview/complete',
        state: {
          info: propUserInfo,
          answers: answer
        }
      });
    }
  }

  const inputSymptoms = (no, id, event) => {
    const questionNo = no;
    const value = event.target.value;
    const answerObj = _.clone(answer);
    
    const targetCategoryIndex = _.findIndex(answerObj.symptoms, (symptoms) => {
      return symptoms.id === id;
    });

    const matchIndex = _.findIndex(answerObj.symptoms[targetCategoryIndex].question, (obj) => {
      return obj.no === questionNo;
    });

    if (matchIndex >= 0) {
      answerObj.symptoms[targetCategoryIndex].question[matchIndex].value = value;
      updataAnswer(answerObj);
    }
  }

  const inputConsultation = (no, id, event) => {
    let checked = event.target.checked;
    const questionNo = no;
    const value = event.target.value;
    const answerObj = _.clone(answer);
    const targetCategoryIndex = _.findIndex(answerObj.consultation, (consult) => {
      return consult.id === id;
    });

    const matchIndex = _.findIndex(answerObj.consultation[targetCategoryIndex].question, (obj) => {
      return obj.no === questionNo;
    });

    if (matchIndex >= 0) {
      if (checked) {
        answerObj.consultation[targetCategoryIndex].question[matchIndex].value = value;
      } else {
        answerObj.consultation[targetCategoryIndex].question[matchIndex].value = '';
      }
      updataAnswer(answerObj);
    }
  }

  const inputOtherText = (event) => {
    const value = event.target.value;
    const answerObj = _.clone(answer);
    answerObj.other.value = value;
    updataAnswer(answerObj);
  }

  const handleClose = () => {
    updateIsOpen(false);
  }

  return (
    <>
      <Header back={false} />
      <div className={RootCSS.container}>
        <p className={CSS.explanation}>
          現在どのくらい以下の症状がありますか。0～4の当てはまるもの選択してください。<br />
          <br />
          0: 全く無い<br />
          1: 少し<br />
          2: まあまあ<br />
          3: 結構<br />
          4: 極めて
        </p>

        {
          answer.symptoms.map((item, index) => {
            return(
              <div key={index} className={CSS.inputContainer}>
                <h3>{item.title}</h3>
                {
                  item.question.map((list, index) => {
                    return (
                      <div key={index} className={CSS.question}>
                        <h4>{list.no}. {list.label}</h4>
                        {
                          list.no === '44' ?
                          <div className={CSS.radioGroup}>
                            <div className={CSS.radioItem}>
                              <input type="radio" name={`question_${list.no}`} id={`question${list.no}_0`} value={'4'} onChange={(event) => inputSymptoms(list.no, item.id, event)} checked={list.value === '4'} />
                              <label htmlFor={`question${list.no}_0`}>
                                <span>あり</span>
                              </label>
                            </div>
                            <div className={CSS.radioItem}>
                              <input type="radio" name={`question_${list.no}`} id={`question${list.no}_1`} value={'0'} onChange={(event) => inputSymptoms(list.no, item.id, event)} checked={list.value === '0'} />
                              <label htmlFor={`question${list.no}_1`}>
                                <span>なし</span>
                              </label>
                            </div>
                          </div>
                          :
                          <div className={CSS.radioGroup}>
                            <div className={CSS.radioItem}>
                              <input type="radio" name={`question_${list.no}`} id={`question${list.no}_0`} value={'0'} onChange={(event) => inputSymptoms(list.no, item.id, event)} checked={list.value === '0'} />
                              <label htmlFor={`question${list.no}_0`}>
                                <span>0</span>
                              </label>
                            </div>
                            <div className={CSS.radioItem}>
                              <input type="radio" name={`question_${list.no}`} id={`question${list.no}_1`} value={'1'} onChange={(event) => inputSymptoms(list.no, item.id, event)} checked={list.value === '1'} />
                              <label htmlFor={`question${list.no}_1`}>
                                <span>1</span>
                              </label>
                            </div>
                            <div className={CSS.radioItem}>
                              <input type="radio" name={`question_${list.no}`} id={`question${list.no}_2`} value={'2'} onChange={(event) => inputSymptoms(list.no, item.id, event)} checked={list.value === '2'} />
                              <label htmlFor={`question${list.no}_2`}>
                                <span>2</span>
                              </label>
                            </div>
                            <div className={CSS.radioItem}>
                              <input type="radio" name={`question_${list.no}`} id={`question${list.no}_3`} value={'3'} onChange={(event) => inputSymptoms(list.no, item.id, event)} checked={list.value === '3'} />
                              <label htmlFor={`question${list.no}_3`}>
                                <span>3</span>
                              </label>
                            </div>
                            <div className={CSS.radioItem}>
                              <input type="radio" name={`question_${list.no}`} id={`question${list.no}_4`} value={'4'} onChange={(event) => inputSymptoms(list.no, item.id, event)} checked={list.value === '4'} />
                              <label htmlFor={`question${list.no}_4`}>
                                <span>4</span>
                              </label>
                            </div>
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }

        <p className={CSS.consultText}>看護師と話をしたい事柄に ☑ をしてください。<br />（複数可）</p>
        {
          answer.consultation.map((list, index) => {
            return (
              <div key={index} className={CSS.checkBoxContainer}>
                <h3>{list.title}</h3>
                {
                  list.question.map((consul, index) => {
                    return (
                      <div key={index} className={CSS.checkBoxItem}>
                        <input type="checkbox" name={`${list.id}`} id={`${list.id}${index}`} value={'1'} onChange={(event) => inputConsultation(consul.no, list.id, event)} checked={consul.value === '1'}/>
                        <label htmlFor={`${list.id}${index}`}>
                          <span>{consul.label}</span>
                        </label>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }

        <p>その他、話したいことがありましたら、下記にお書きください</p>
        <div className={CSS.freeInput}>
          <textarea name="other" id="other" cols="30" rows="10" onChange={inputOtherText} defaultValue={answer.other.value}></textarea>
        </div>

        <div className={CSS.buttons}>
          <Button 
            color="red" 
            text="先生に見せる" 
            customeClass=""
            onClick={hrefInterviewComplete}
          />
        </div>
      </div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={CSS.emptyDialog}>
          <DialogContentText>
            未選択の項目があります。<br />
            <span className={CSS.emptyNumbersContainer}>
              {
                emptyNumbers.map((no, index) => {
                  return (
                    <span key={index}>{no}.</span>
                  )
                })
              }
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogButton onClick={handleClose}>
            OK
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InterviewInput;