import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import CSS from './MemoInput.module.scss';
import RootCSS from '../index.module.scss';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import liff from '@line/liff';

import Header from './partial/Header';
import Button from './partial/Button';

import useApiService from "../hooks/useApiService";

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { formatDate } from "@fullcalendar/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MemoInput = (props) => {
  const history = useHistory();
  const location = useLocation();
  const todayDatetime = dayjs().set('second', 0).format('YYYY-MM-DDThh:mm:ss');
  
  const propTypeObj = location.state.typeData;
  const propId = propTypeObj.kininarukoto.id;
  const propText = propTypeObj.kininarukoto.label;
  const propKininarukoto = propTypeObj.kininarukoto;
  const propShoujous = propTypeObj.shoujou;

  const [date, updateDate] = useState(todayDatetime);
  const { apiImageUpload, apiMemoSave } = useApiService(); // apiServiceフック
  const [textareaInput, updateTextAreaInput] = useState(''); // 備考入力HTML
  const [isOpen, updateIsOpen] = useState(false); // 保存ダイアログフラグ
  const [shoujouObjects, updateShoujouObjects] = useState(propShoujous);

  const checkboxRef = React.createRef();
  const textAreaRef = React.createRef();
  
  /**
   * 備考入力内容のhtmlをバインドします
   * @param {object} event inputEvent
   */
  const changeTextArea = (event) => {
    updateTextAreaInput(event.target.innerHTML)
  }

  /**
   * 画像をアップロードして備考に挿入します
   * @param {object} event inputEvent
   */
  const imageUpload = async (event) => {
    let param = new FormData();
    const file = event.target.files[0];
    param.append('file', file);

    try {
      const imageUploadResponse = await apiImageUpload(param);
      const url = imageUploadResponse.image;
      const imgElement = `<img src="${url}" /><br />`;
      const targetElement = document.getElementById('inputArea');
      targetElement.insertAdjacentHTML('beforeend', imgElement);
      updateTextAreaInput(targetElement.innerHTML);
      const inputElement = document.getElementById('uploadFile');
      inputElement.value = '';
      setTimeout(() => {
        let selection = window.getSelection();
        
        if (selection) {
          let range = document.createRange();
          selection.removeAllRanges();
          range.selectNodeContents(targetElement);
          range.collapse(false);
          selection.addRange(range);
        }
        targetElement.focus();
      }, 300);
    } catch(error) {
      console.log(error);
    }
    // const reader = new FileReader();
    // reader.addEventListener('load', (event) => {
    //   const url = event.target.result;
    //   const imgElement = `<figure><img src="${url}" /></figure><br />`;
    //   const targetElement = document.getElementById('inputArea');
    //   targetElement.insertAdjacentHTML('beforeend', imgElement);
    //   updateTextAreaInput(targetElement.innerHTML);
    //   const inputElement = document.getElementById('uploadFile');
    //   inputElement.value = '';
    //   targetElement.focus();
    // });

    // if (file) {
    //   reader.readAsDataURL(file)
    // }
  }
  
  /**
   * 記録を保存します
   */
  const saveMemo = () => {
    const param = {
      'update': false,
      'recordId': '',
      'time': date,
      'kininarukoto': propKininarukoto,
      'shoujou': shoujouObjects,
      'detail': textareaInput
    }
    apiMemoSave(param)
      .then(response => {
        updateIsOpen(true);

        if (liff.isInClient()) {
          setTimeout(() => {
            liff.closeWindow();
          }, 2000);
        } else {
          setTimeout(() => {
            updateIsOpen(false);
          }, 2000);
        }
      }, error => {
        console.log(error);
      });
  }

  const handleClose = () => {
    updateIsOpen(false);
    setTimeout(() => {

    });
  }

  const handleCheckbox = (event) => {
    const value = event.target.value;
    const replaceShoujouObjects = _.map(shoujouObjects, (item) => {
      if (item.id === value) {
        item.checked = !item.checked;
        return item;
      } else {
        return item;
      }
    });
    updateShoujouObjects(replaceShoujouObjects);
  }

  return (
    <>
      <Header back={false} router={history} />
      <div className={RootCSS.container}>
        <h1 className={CSS.title}>日時</h1>
        <div className={CSS.datetime}>
          <input type="datetime-local" defaultValue={date} onChange={(event) => {updateDate(event.target.value)}} />
        </div>
        {
          propId === 'reserve' ?
          ''
          :
          <h1 className={CSS.title}>気になること</h1>
        }
        <div className={CSS.mind}>
          {propText}
        </div>
        {
          propId === 'reserve' ?
          ''
          :
          <h1 className={CSS.title}>症状</h1>
        }
        <div className={CSS.detailContainer}>
          <FormGroup>
            {
              shoujouObjects.map((shoujou, index) => {
                return (
                  <FormControlLabel 
                    key={index} 
                    className={CSS.checkBoxItem} 
                    control={
                      <Checkbox 
                        name={propId}
                        value={shoujou.id}
                        onChange={(event) => { handleCheckbox(event) }}
                        checked={shoujou.checked} 
                      />
                    } 
                    label={shoujou.label}
                  />
                )
              })
            }
            <div className={CSS.freeInput}>
              <div className={CSS.textarea} ref={textAreaRef} id="inputArea" value={textareaInput} onInput={changeTextArea} contentEditable="true" placeholder="自由記載"></div>
            </div>
            {
              propId === 'skin' || propId === 'reserve' ?
              <div className={CSS.imageUpload}>
                <label htmlFor="uploadFile" className={CSS.uploadButton}>
                  <img src={`${process.env.PUBLIC_URL}/images/camera.svg`} alt="カメラ" />
                  <input type="file" name="uploadFile" id="uploadFile" accept="image/*" defaultValue="" onChange={imageUpload}/>
                </label>
              </div>
              :
              ''
            }
          </FormGroup>
        </div>
        {/* {this.state.textareaInput} */}
        <div className={CSS.buttons}>
          {/* <Button 
            color="red" 
            text="一般的な症状から記録" 
            customeClass=""
          /> */}
          <Button 
            color="red" 
            text="保存" 
            customeClass=""
            onClick={saveMemo}
          />
        </div>
      </div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={CSS.saveDialog}
      >
        <DialogContent className={CSS.saveDialogContainer}>
          保存しました。
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MemoInput;