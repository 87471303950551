import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import CSS from './Memo.module.scss';
import RootCSS from '../index.module.scss';

// import Button from './partial/Button';
import Header from "./partial/Header";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import useApiService from "../hooks/useApiService";
import { logDOM } from "@testing-library/react";

const Memo = (props) => {
  const history = useHistory();
  const [typeData, updateTypeData] = useState([]);
  const { apiMemoInput } = useApiService();
  
  useEffect(() => {
    const fetchData = async () => {
      const param = {
        'edit': false,
        'recordId': ''
      };
      const memoResponse = await apiMemoInput(param);
      updateTypeData(memoResponse.kiroku);
    };
    fetchData();
  }, [])

  const hrefInput = (typeObj, event) => {
    history.push({
      pathname: '/memo/input',
      state: {
        typeData: typeObj
      }
    });
  }

  return (
    <>
      <Header back={false}/>
      <div className={RootCSS.container}>
        <p>症状で気になることを選択して下さい。</p>
        <div className={CSS.form_area}>
          <List className={CSS.lists}>
            {
              typeData.map((type, index) => {
                const id = type.kininarukoto.id;
                const typeClassName = `color${id}`; 
                return (
                    <ListItem 
                      key={index}
                      className={`${CSS.listItems} ${CSS[typeClassName]}`}
                      button={true}
                      divider={true}
                      onClick={(event) => hrefInput(type, event)}
                    >
                      {type.kininarukoto.label}
                    </ListItem>
                  )
                })
              }
            {/* <ListItem className={`${CSS.listItems} ${CSS.colorSkin}`} button={true} divider={true} onClick={(event) => hrefInput('skin', event)}>皮膚の状態</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorEye}`} button={true} divider={true} onClick={(event) => hrefInput('eye', event)}>目の状態</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorMouse}`} button={true} divider={true} onClick={(event) => hrefInput('mouse', event)}>口の状態</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorBreath}`} button={true} divider={true} onClick={(event) => hrefInput('breath', event)}>呼吸</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorStomach}`} button={true} divider={true} onClick={(event) => hrefInput('stomach', event)}>お腹</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorMuscle}`} button={true} divider={true} onClick={(event) => hrefInput('muscle', event)}>関節、筋肉</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorPrivate}`} button={true} divider={true} onClick={(event) => hrefInput('private', event)}>陰部、性</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorOther}`} button={true} divider={true} onClick={(event) => hrefInput('other', event)}>体調全般</ListItem>
            <ListItem className={`${CSS.listItems} ${CSS.colorReserve}`} button={true} divider={true} onClick={(event) => hrefInput('reserve', event)}>病院予約</ListItem> */}
          </List>
        </div>
      </div>
    </>
  );
}

export default Memo;