import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import CSS from './Karte.module.scss';
import RootCSS from '../index.module.scss';
import * as _ from 'lodash';

import useApiService from "../hooks/useApiService";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { createTheme, ThemeProvider } from '@mui/material/styles';
const bottomDrawerTheme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20
        }
      }
    }
  }
});

const Karte = () => {
  const history = useHistory();
  const screenHeight = window.innerHeight;
  const calendarHeight = screenHeight - 50;
  const calendarRef = useRef(null);  

  const { apiMemoList, apiMemoInput } = useApiService();
  const [events, updateEvents] = useState([]);
  const [typeData, updateTypeData] = useState([]);
  const [allEvents, updateAllEvents] = useState([]); // filter用全イベント
  const [title, updateTitle] = useState('');
  const [calendarType, updateCalendartype] = useState('dayGridMonth');
  const [isOpenLeft, updateIsOpenLeft] = useState(false);
  const [isOpenRight, updateIsOpenRight] = useState(false);
  const [isOpenBottom, updateIsOpenBottom] = useState(false);
  const [selectEvent, updateSelectEvent] = useState([]);
  const [selectFiltertype, updateSelectFiltertype] = useState('')
  
  useEffect(() => {
    const fetchData = async () => {
      const listParam = '';
      const memoListResponse = await apiMemoList(listParam);

      updateEvents(memoListResponse.memo);
      updateAllEvents(memoListResponse.memo);

      const inputParam = {
        'edit': false,
        'recordId': ''
      };
      const memoTypeResponse = await apiMemoInput(inputParam);
      updateTypeData(memoTypeResponse.kiroku);
    };
    fetchData();
  }, []);

  const mountCalendar = (event) => {
    const currentTitle = event.view.getCurrentData().viewTitle || '';
    updateTitle(currentTitle)
  }
  
  const handleOpenLeft = () => {
    updateIsOpenLeft(true)
  }

  const handleCloseLeft = (type) => {
    if (type !== 'close') {
      const targetEvent = _.filter(allEvents, (event) => {

        if (type === '') {
          return event;
        } else {
          return type === event.kininarukoto.id; 
        }
      })
      updateEvents(targetEvent);
    }
    updateIsOpenLeft(false);

    if (type !== 'close') {
      updateSelectFiltertype(type);
    }
  }
  
  const handleOpenRight = () => {
    updateIsOpenRight(true);
  }

  const handleCloseRight = () => {
    updateIsOpenRight(false);
  }
  
  const handleOpenBottom = (info) => {
    switch(calendarType) {
      case 'dayGridMonth':
        const targetDate = info.dateStr;
        const targetMonthEvents = _.filter(events, (event) => {
          return _.includes(event.start, targetDate);
        });
        updateSelectEvent(targetMonthEvents);
        break;

      case 'listWeek':
        const targetId = info.event.extendedProps.recordId;
        const targetListEvent = _.filter(events, (event) => {
          return event.recordId === targetId;
        });
        updateSelectEvent(targetListEvent);
        break;
    }
    updateIsOpenBottom(true);
  }

  const handleCloseBottom = () => {
    updateIsOpenBottom(false);
  }

  const updateCalendarType = (type) => {
    let calendarApi = calendarRef.current.getApi();
    updateCalendartype(type);
    calendarApi.changeView(type);
    updateIsOpenRight(false);
  }

  const cellContent = (event) => {
    event.dayNumberText = event.dayNumberText.replace('日', '');
  }

  const hrefEventDetail = (eventObj) => {
    updateIsOpenBottom(false);
    setTimeout(() => {
      history.push({
        pathname: '/karte/detail',
        state: {
          event: eventObj
        }
      });
      
    }, 200);
  }

  const calendarPrev = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    const title = calendarApi.view.getCurrentData().viewTitle;
    updateTitle(title)
  }

  const calendarNext = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    const title = calendarApi.view.getCurrentData().viewTitle;
    updateTitle(title)
  }

  return (
    <>
      <div className={RootCSS.calendarContainer}>
        <div className={CSS.calendarTitle}>
          <div className={CSS.toolbarLeft}>
            <div className={CSS.filter} onClick={handleOpenLeft}>
              <img src={`${process.env.PUBLIC_URL}/images/filter.svg`} alt="絞り込み" />
            </div>
          </div>
          <div className={CSS.toolbarCenter}>
            <div className={`${CSS.calendarArrow} ${CSS.prev}`} onClick={calendarPrev}>
              <img src={`${process.env.PUBLIC_URL}/images/calendar_prev.svg`} alt="前" />
            </div>
            <span>{title}</span>
            <div className={`${CSS.calendarArrow} ${CSS.next}`} onClick={calendarNext}>
              <img src={`${process.env.PUBLIC_URL}/images/calendar_next.svg`} alt="次" />
            </div>
          </div>
          <div className={CSS.toolbarRight}>
            <div className={CSS.selectType} onClick={handleOpenRight}>
              <img src={`${process.env.PUBLIC_URL}/images/calendar_type.svg`} alt="表示変更" />
            </div>
          </div>
        </div>
        <FullCalendar
          plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ]}
          // initialView={type === 'month' ? "dayGridMonth" : 'timeGridWeek'} 
          initialView={'dayGridMonth'} 
          locale="ja"
          height={calendarHeight}
          events={events}
          // eventsSet={setEvents}
          headerToolbar=""
          viewDidMount={mountCalendar}
          eventClassNames={CSS.eventItems}
          dayCellContent={cellContent}
          dayHeaderClassNames={CSS.dayHeaderItem}
          dateClick={handleOpenBottom}
          firstDay={1}
          ref={calendarRef}
          eventClick={handleOpenBottom}
          noEventsContent={`登録されている記録がありません。`}
        />
      </div>
      {/* カレンダー切り替え */}
      <Drawer
        anchor={'left'}
        open={isOpenLeft}
        onClose={() => handleCloseLeft('close')}
      >
        <div className={CSS.filterContainer}>
          <div className={CSS.form_area}>
            <List className={CSS.lists}>
              <ListItem className={`${CSS.listItems} ${selectFiltertype === '' ? CSS.selected : ''}`} button={true} divider={true} onClick={() => handleCloseLeft('')}>全て</ListItem>
              {
                typeData.map((type, index) => {
                  const listType = type.kininarukoto.id;
                  const typeClassName = `color-${listType}`; 
                  return (
                    <ListItem 
                      key={index}
                      className={`${CSS.listItems} ${CSS[typeClassName]} ${selectFiltertype === listType ? CSS.selected : ''}`} 
                      button={true} 
                      divider={true} 
                      onClick={() => handleCloseLeft('skin')}
                    >
                      {type.kininarukoto.label}
                    </ListItem>
                  )
                })
              }
            </List>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor={'right'}
        open={isOpenRight}
        onClose={handleCloseRight}
      >
        <div className={CSS.selectTypeContainer}>
          <ul className={CSS.selectTypeList}>
            <li onClick={() => updateCalendarType('dayGridMonth')}>月表示</li>
            <li onClick={() => updateCalendarType('listWeek')}>週表示</li>
          </ul>
        </div>
      </Drawer>
      <ThemeProvider theme={bottomDrawerTheme}>
        <Drawer
          anchor={'bottom'}
          open={isOpenBottom}
          onClose={handleCloseBottom}
          PaperProps={{style: {maxHeight: '60vh'}}}
        >
          <div className={CSS.selectEventContainer}>
            {
              selectEvent.length > 0 ?
              <>
                {
                  selectEvent.map((event, index) => {
                    return (
                      <div className={CSS.eventItemList} key={index} onClick={() => hrefEventDetail(event)}>
                        <dl>
                          <dt>登録日時</dt>
                          <dd>{event.time}</dd>
                        </dl>
                        <dl>
                          <dt>
                            {
                              event.kininarukoto.id === 'reserve' ? 
                              ''
                              :
                              '気になること'
                            }
                          </dt>
                          <dd>{event.title}</dd>
                        </dl>
                        {
                          _.size(_.get(event, 'shoujou', [])) > 0 ?
                          <dl>
                            <dt>症状</dt>
                            {
                              event.shoujou.map((shoujou, index) => {
                                return (
                                  <dd key={index}>{shoujou.label}</dd>    
                                )
                              })
                            }
                          </dl>
                          :
                          ''
                        }
                      </div>
                    )
                  })
                }
              </>
              :
              <>
                <p>記録がありません。</p>
              </>
            }
            
          </div>
        </Drawer>
      </ThemeProvider>
    </>
  );
}
export default Karte;