import React from "react";
import { useHistory } from 'react-router-dom';
import CSS from './Header.module.scss';

const Header = (props) => {
  const history = useHistory();
  const pop = () => {
    history.goBack();
  }
  return (
    <>
      <header>
        <div className={CSS.toolbarLeft}>
          {
            props.back ? 
            <div className={CSS.back} onClick={pop}>
              <img src={`${process.env.PUBLIC_URL}/images/back.svg`} alt="戻る" />
            </div>
            :
            ''
          }
        </div>
        <div className={CSS.toolbarCenter}>
          <div className={CSS.logo}>
            <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="アステラス製薬" />
          </div>
        </div>
        <div className={CSS.toolbarRight}>
        </div>
      </header>
    </>
  );
};

export default Header;