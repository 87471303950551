import React, { useState } from "react";
import CSS from './Button.module.scss';

const Button = (props) => {
  const colorClass = props.color ? props.color : '';
  const customClass = props.customClass ? props.customClass : '';
  const inputType = props.inputType ? props.inputType : false;
  const disabledFlag = props.disabled ? props.disabled : false;

  return (
    <>
      <button type="button" className={`${CSS.common_button} ${CSS[colorClass]} ${CSS[customClass]}`} onClick={props.onClick} disabled={disabledFlag}>
        {props.text}
      </button>
    </>
  );
}

export default Button;