import { atom } from 'recoil';

export const dialogState = atom({
  key: 'dialogIsOpen',
  default: {
    'flag': false,
    'message': ''
  },
});

export const spinnerState = atom({
  key: 'spinnerIsOpen',
  default: {
    'flag': false
  },
})