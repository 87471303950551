module.exports = {
  apiUrl: 'https://api.astellas-patient-follow.gjd.jp/api',
  apiInfos: {
    Faq: {
      method: 'GET',
      path: '/faq'
    },
    Voice: {
      method: 'GET',
      path: '/voice'
    },
    MemoInput: {
      method: 'POST',
      path: '/memo/input'
    },
    MemoList: {
      method: 'GET',
      path: '/memo/list'
    },
    MemoDetail: {
      method: 'GET',
      path: '/memo/detail'
    },
    MemoDelete: {
      method: 'POST',
      path: '/memo/delete'
    },
    MemoSave: {
      method: 'POST',
      path: '/memo/save'
    },
    ImageUpload: {
      method: 'POST',
      path: '/memo/imageupload'
    },
    InterviewComplete: {
      method: 'POST',
      path: '/interview/complete'
    }
  }
};
