import React, { useState, useEffect, useRef } from "react";
// import { Link } from 'react-router-dom';

import CSS from './Voice.module.scss';
import RootCSS from '../index.module.scss';
import * as _ from 'lodash';

import Header from "./partial/Header";
import useApiService from "../hooks/useApiService";

const Voice = () => {
  const [voices, updateVoices] = useState([]);
  const [allVoices, updateAllVoices] = useState([]); // filter用全データ
  const {apiVoice} = useApiService();
  const [searchFlag, updateSearchFlag] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const param = '';
      const voiceResponse = await apiVoice(param);
      updateVoices(voiceResponse.voice);
      updateAllVoices(voiceResponse.voice);
    }
    fetchData();
  }, []);

  const toggleSearch = () => {
    const flag = !searchFlag;
    if (flag) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
    updateSearchFlag(flag);
  }

  const inputSearchWord = (event) => {
    const value = event.target.value;
    if (value === '') {
      updateVoices(allVoices);
    } else {
      const targetObj = _.filter(allVoices, (item) => {
        return _.includes(item.text, value);
      });
      updateVoices(targetObj);
      window.scrollTo({top: 0});
    }
  }

  return (
    <>
      <Header back={false} />
      <div className={RootCSS.container}>
        <div className={CSS.voiceWrapper}>
          <div className={CSS.voiceItemWrapper}>
            {
              voices.map((voice, index) => {
                return (
                  <div className={CSS.voiceItem} key={index}>
                    <div className={CSS.voiceItemLeft}>
                      <img src={`${process.env.PUBLIC_URL}/images/user.svg`} alt="ユーザー" />
                    </div>
                    <div className={CSS.voiceItemRight}>
                      <div className={CSS.commnet}>
                        {voice.text}
                      </div>
                      <span className={CSS.date}>{voice.date}</span>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className={`${CSS.floatingSearch} ${searchFlag ? CSS.open : ''}`}>
            <div className={CSS.searchIcon} onClick={toggleSearch}>
              <img src={`${process.env.PUBLIC_URL}/images/search_icon.svg`} className={CSS.search} alt="検索" />
              <img src={`${process.env.PUBLIC_URL}/images/close_icon.svg`} className={CSS.close} alt="閉じる" />
            </div>
            <div className={CSS.searchInput}>
              <input type="text" placeholder={`検索ワードを入力して下さい。`} onBlur={inputSearchWord} ref={inputRef} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Voice;