import React from 'react';
import ReactDOM from 'react-dom';
import './index.module.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter }from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import liff from '@line/liff';
import _ from "lodash";

import VConsole from 'vconsole';
// const vConsole = new VConsole();

const origin = String(window.location.origin);
let id;
if (_.includes(origin, `${process.env.REACT_APP_ORIGIN_PRODUCTION}`)) {
  id = `${process.env.REACT_APP_ID_PRODUCTION}`;
} else if (_.includes(origin, `${process.env.REACT_APP_ORIGIN_STAGING}`)) {
  id = `${process.env.REACT_APP_ID_STAGING}`;
} else if (_.includes(origin, `${process.env.REACT_APP_ORIGIN_DEVELOPMENT}`)) {
  id = `${process.env.REACT_APP_ID_DEVELOPMENT}`;
} else {
  id = `${process.env.REACT_APP_ID_LOCAL}`;
}
// console.log(`LiffId: ${id}`);
liff.init({
  liffId: id, // Use own liffId
}).then(() => {
  console.log(`>>> LIFF INIT`);
  const isLocalhost = window.location.hostname === 'localhost' || /192\.168/.test(window.location.hostname) ? true : false;
  const isLogin = isLocalhost ? true : liff.isLoggedIn();
  console.log(`isLogin: ${isLogin}`);

  if (isLogin) {
    ReactDOM.render(
      <BrowserRouter>
        {/* <React.StrictMode> */}
        <RecoilRoot>
          <App />
        </RecoilRoot>
        {/* </React.StrictMode> */}
      </BrowserRouter>,
      document.getElementById('root')
    );
  } else {
    liff.login();
  }
}, error => {
  // alert('予期せぬエラーが発生しました。')
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
