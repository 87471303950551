import React, { useState, useEffect, useContext } from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import _ from "lodash";
import liff from '@line/liff';

import { useRecoilState } from 'recoil';
import { dialogState } from '../stores/atom';
import { spinnerState } from '../stores/atom';

const env_local = require('../environments/environment.local.js');
const env_dev   = require('../environments/environment.development.js');
const env_stg   = require('../environments/environment.staging.js');
const env_prd   = require('../environments/environment.production.js');

export const useApiService = (key, data, props) => {
  // const [response, updateResponse] = useState(null);
  const [state, updateState] = useRecoilState(dialogState);
  const [spinner, updateSpinnerState] = useRecoilState(spinnerState);

  // useEffect(() => {
  //   async function fetchApiObject() {
  //     await getApiObject(key, data);
  //   };
  //   fetchApiObject();
  // }, [response]);

  /***************************
  *
  * 呼び出し用の関数を定義
  *
  ***************************/
 /**
  * FAQ取得API
  */
  const apiFaq = (param) => {
    return getApiObject('Faq', param);
  }

  /**
   * みんなの声取得API
   */
  const apiVoice = (param) => {
    return getApiObject('Voice', param)
  }

  /**
   * 記録入力項目取得API
   */
  const apiMemoInput = (param) => {
    return getApiObject('MemoInput', param);
  }

  /**
   * 記録一覧取得API
   */
   const apiMemoList = (param) => {
    return getApiObject('MemoList', param);
  }

  /**
   * 記録詳細取得API
   */
   const apiMemoDetail = (param) => {
    return getApiObject('MemoDetail', param);
  }

  /**
   * 記録削除API
   */
   const apiMemoDelete = (param) => {
    return getApiObject('MemoDelete', param);
  }

  /**
   * 記録保存API
   */
  const apiMemoSave = (param) => {
    return getApiObject('MemoSave', param);
  }

  /**
   * 画像アップロード
   */
  const apiImageUpload = (param) => {
    return getApiObject('ImageUpload', param);
  }

  /**
   * 問診票PDF作成
   */
  const apiInterviewComplete = (param) => {
    return fetchApiInterviewPdfObject('InterviewComplete', param);
  }

  /***************************
  *
  * 共通処理用関数を定義
  *
  ***************************/
 /**
  * 現在の環境をドメインから判断して返却します
  * @returns env
  */
  const getCurrentEnv = () => {
    const origin = String(window.location.origin);

    let env;
    if (_.includes(origin, `${process.env.REACT_APP_ORIGIN_PRODUCTION}`)) {
      env = 'PRODUCTION';
    } else if (_.includes(origin, `${process.env.REACT_APP_ORIGIN_STAGING}`)) {
      env = 'STAGING';
    } else if (_.includes(origin, `${process.env.REACT_APP_ORIGIN_DEVELOPMENT}`)) {
      env = 'DEVELOPMENT';
      // env = 'LOCAL';
    } else {
      env = 'LOCAL';
      // env = 'DEVELOPMENT';
    }
    return env;
  }

  /**
   * 現在の環境に応じた環境変数を返却します
   * @returns envData
   */
  const getCurrentEnvData = () => {
    const current = getCurrentEnv();

    let envData;
    switch (current) {
      case 'PRODUCTION':
        envData = env_prd;
        break;

      case 'STAGING':
        envData = env_stg;
        break;

      case 'DEVELOPMENT':
        envData = env_dev;
        break;

      default:
        envData = env_local;
        break;
    }
    return envData;
  }

  /**
   * ローカルでAPI通信を行おうとしているかチェックします
   */
  const localApiCheck = () => {
    return getCurrentEnv() === 'DEVELOPMENT' && window.location.hostname === 'localhost';
  }

  /**
   * APIのレスポンスを返却します
   * @param {string} apiKey apiInfosキー
   * @param {object} data   パラメーター
   * @returns 
   */
  const getApiObject = (apiKey, data) => {
    if (apiKey === undefined) {
      return;
    }
    console.log(`\x1b[36mkey: \u001b[0m${apiKey}, \x1b[36mdata: \u001b[0m${JSON.stringify(data)}`);
    updateSpinnerState({'flag': true});
    const environment = getCurrentEnvData();
    const apiInfo = environment.apiInfos[apiKey];
    const apiBaseUrl = environment.apiUrl;
    const apiUrl = apiBaseUrl + apiInfo.path;
    const appToken = localApiCheck() ? 'localhost' : liff.getIDToken();
    const contentType = apiInfo.method === 'POST' ? 'application/json' : 'no-cache';
    const headers = {
      'Content-Type': contentType,
      'Authorization': `Bearer ${appToken}`
    };

    const instance = axios.create({
      baseURL: apiBaseUrl,
      headers: headers
    });

    const param = getCurrentEnv() === 'LOCAL' ? '' : data; // LOCAL時はパラメーターを付けない

    if (apiInfo.method === 'POST') {
      return instance.post(apiUrl, param)
        .then((res) => {
          if (res.data.ok) {
            return handleSuccessFunc(res);
          }
        })
        .catch((error) => {
          return handleErrorFunc(error);
        });
    } else {
      return instance.get(`${apiUrl}${param}`)
        .then((res) => {
          if (res.data.ok) {
            return handleSuccessFunc(res);
          }
        })
        .catch((error) => {
          return handleErrorFunc(error);
        });
    }
  }

  /**
   * 問診票回答のPDFを返却します
   * @param {string} apiKey apiInfosキー
   * @param {object} data   パラメーター
   * @returns 
   */
  const fetchApiInterviewPdfObject = async (apiKey, data) => {
    updateSpinnerState({'flag': true});

    const environment = getCurrentEnvData();
    const apiInfo = environment.apiInfos[apiKey];
    const apiBaseUrl = environment.apiUrl;
    const apiUrl = apiBaseUrl + apiInfo.path;
    const appToken = localApiCheck() ? 'localhost' : liff.getIDToken();
    const contentType = 'application/json';
    const headers = {
      'Content-Type': contentType,
      'Authorization': `Bearer ${appToken}`
    };

    let fetchOption;

    if (apiInfo.method === 'GET') {
      fetchOption = {
        method: apiInfo.method,
        headers: headers,
      }
    } else {
      fetchOption = {
        method: apiInfo.method,
        headers: headers,
        body: `${JSON.stringify(data)}`
      }
    }

    
    return fetch(apiUrl, fetchOption)
    .then(async res => {
      if (res.ok) {
        setTimeout(() => {
          updateSpinnerState({'flag': false});
        }, 300);
        return res;
      } else {
        const json = await res.json();
        throw json;
      }
    })
    .catch(error => {
      const message = error.message;
      if (message === undefined) {
        return;
      }
      setTimeout(() => {
        updateSpinnerState({'flag': false});
      }, 300);
      updateState({'flag': true, 'message': message});
      throw error.message;
    });
  }

  /**
   * 通信成功時のレスポンスを返却します
   * @param {object} res レスポンス
   * @returns 
   */
  const handleSuccessFunc = (res) => {
    console.log(res);
    // updateResponse(res.data);
    setTimeout(() => {
      updateSpinnerState({'flag': false});
    }, 300);
    return res.data;
  }

  /**
   * 通信失敗時のエラーメッセージを返却します
   * @param {string} message エラーメッセージ
   * @returns 
   */
  const handleErrorFunc = (error) => {
    console.log(error.response);
    const message = error.response.data.message;
    if (message === undefined) {
      return;
    }
    console.log(`\u001b[31mERROR: ${message}\u001b[0m`);
    setTimeout(() => {
      updateSpinnerState({'flag': false});
    }, 300);
    updateState({'flag': true, 'message': message});
    throw error;
  }

  /**
   * Hooksで返却する内容
   */
  return {
    // response,
    // 設定関数
    apiFaq,
    apiVoice,
    apiMemoInput,
    apiMemoList,
    apiMemoDetail,
    apiMemoDelete,
    apiMemoSave,
    apiImageUpload,
    apiInterviewComplete
  };
}

export default useApiService